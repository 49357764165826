/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('./src/styles/global.css')
require("normalize.css")
const axios = require("axios")
const ReactDOM = require("react-dom")

const dataLayer = typeof window !== "undefined" ? window.dataLayer || [] : null

exports.replaceHydrateFunction = function() {
    return function(element, container) {
      const root = ReactDOM.createRoot(container);
      root.render(element);
    }
  }

const filteredIpAddresses = [
    "49.206.255.19",      // Dutchview India
    "81.28.93.122",       // Dutchview Netherlands
    "83.81.224.72",      // Wiebe's Home
    "77.163.93.221",        // Jim's Home
    "117.213.169.222",    // Kiran's Home
    "88.159.229.126",        //Magda's Home
    "85.144.157.27",        //Clara's Home
    "143.176.5.171",           //Martin's Home
    "45.10.231.143",         // Vsee office
    "195.201.111.103",       //Audit Site SE ranking       
    "195.201.111.104",       //Audit Site SE ranking
    "94.130.21.184",         //Audit Site SE ranking
    "46.4.10.233",           //Audit Site SE ranking
    "162.55.244.19",         //Audit Site SE ranking
    "116.202.175.208",       //Audit Site SE ranking
    "162.55.90.109",         //Audit Site SE ranking
    "168.119.64.236",        //Audit Site SE ranking
    "85.208.98.128",      //SEM Rush audit
    "109.153.129.124",       //Orla Home
    "77.162.151.147",        //Elaine H
    "92.119.179.170",       //VPN NordLayer Netherlands
]


//creating function to load ip address from the API
const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    const ipAddress = res.data.IPv4

    if (filteredIpAddresses.includes(ipAddress)) {
        dataLayer.push({
            'event': "IpEvent",
            'internalAddress': true
        })
    } else {
        dataLayer.push({
            'event': "IpEvent",
            'internalAddress': false
        })
    }
}

exports.onClientEntry = () => {
    getData()
}